import { ENVIRONMENTS } from '@futureverse/experience-sdk'

export const EXPLORER_URL =
  process.env.NEXT_PUBLIC_EXPLORER_URL || 'https://explorer.rootnet.cloud'

export const ENVIRONMENT = process.env.NEXT_PUBLIC_FUTUREPASS_ENV
  ? ENVIRONMENTS[
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      process.env.NEXT_PUBLIC_FUTUREPASS_ENV! as 'staging' | 'production'
    ]
  : ENVIRONMENTS.production

export const getBaseUrl = () => {
  if (typeof window !== 'undefined') {
    return ''
  }
  if (process.browser) return '' // Browser should use current path
  if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}` // SSR should use vercel url

  return `http://localhost:${process.env.PORT ?? 3000}` // dev SSR should use localhost
}
