import { ethers } from 'ethers6'

import {
  CONTRACT_ADDRESSES,
  ROOT_JSON_RPC_URL,
  ASTO_ASSET_ID,
  XRP_ASSET_ID,
} from './constants'

import {
  getRootApi,
  blake2AsHex,
  type ApiPromise,
  type GenericSignerPayload,
} from 'utils/rootApi'

import AstoABI from '../abi/ASTO.json'
import TempusABI from '../abi/Tempus.json'
import ComicABI from '../abi/Comic.json'
import ComicStorageABI from '../abi/ComicStorage.json'
import FuturePassABI from '../abi/FuturePassIdentityRegistry.json'
import { FetchSignerResult, Signer } from '@wagmi/core'

const FeeProxyAbi = [
  'function callWithFeePreferences(address asset, uint128 maxPayment, address target, bytes input)',
]

const ABI_MAP = {
  FEE_PROXY: FeeProxyAbi,
  ASTO: AstoABI,
  TEMPUS: TempusABI,
  TEMPUS_COMIC: ComicABI,
  TEMPUS_COMIC_STORAGE: ComicStorageABI,
  IDENTITY: FuturePassABI,
}

export const getContract = async (
  contractKey: ContractKey,
  signer: FetchSignerResult<Signer>
) => {
  if (!signer) {
    throw new Error('No signer')
  }

  return new ethers.Contract(
    CONTRACT_ADDRESSES[contractKey],
    ABI_MAP[contractKey],
    // @ts-ignore
    signer
  )
}

type ContractKey = keyof typeof ABI_MAP

export const contractCallWithAstoGas = async (
  contractKey: ContractKey,
  signer: FetchSignerResult<Signer>,
  fnName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  args: any[]
) => {
  const callData = await serializeContractCall(
    contractKey,
    signer,
    fnName,
    args
  )

  const callDataWithGas = await getAstoGasFee(callData)

  await callViaFeeProxy(callDataWithGas, signer)
}

export const serializeContractCall = async (
  contractKey: ContractKey,
  signer: FetchSignerResult<Signer>,
  fnName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  args: any[]
) => {
  const contract = await getContract(contractKey, signer)

  if (!contract) {
    throw new Error(`Missing contract instance (${contractKey})`)
  }

  const innerCallTarget = CONTRACT_ADDRESSES[contractKey]

  const abi = new ethers.Interface(ABI_MAP[contractKey])
  const innerCallData = abi.encodeFunctionData(fnName, args)

  return {
    fnName,
    args,
    contract,
    innerCallTarget,
    innerCallData,
    signer,
  }
}

export const getAstoGasFee = async ({
  contract,
  fnName,
  args,
  innerCallTarget,
  innerCallData,
  signer,
}: Awaited<ReturnType<typeof serializeContractCall>>) => {
  const account = await signer?.getAddress()

  if (!account) {
    throw new Error('Not signed in')
  }

  if (!signer) {
    throw new Error('No signer')
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const gasPrice = await signer.getGasPrice()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const gasEstimate = await contract[fnName]!.estimateGas(...args)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion

  const rootApi = await getRootApi()

  const tx = rootApi.tx.evm?.call?.(
    // sender
    account,
    // target
    innerCallTarget,
    // encoded input
    innerCallData,
    // tx value
    0,
    // gasLimit
    gasEstimate.toString(),
    // maxFeePerGas
    gasPrice.toString(),
    // maxPriorityFeePerGas
    null,
    // nonce
    null,
    // accessList
    []
  )
  const paymentInfo = await tx?.paymentInfo(account)
  const partialFee = paymentInfo?.toJSON()?.partialFee

  const xrpCost = BigInt(Math.floor((partialFee as number) * 1.2))

  const data = await fetch(ROOT_JSON_RPC_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: 1,
      jsonrpc: '2.0',
      method: 'dex_getAmountsOut',
      params: [Number(xrpCost), [XRP_ASSET_ID, ASTO_ASSET_ID]],
    }),
  }).then((res) => {
    if (res.ok) {
      return res.json()
    }

    throw new Error('Failed to fetch')
  })

  const maxPayment = data.result.Ok[1]

  return {
    maxPayment: BigInt(maxPayment),
    account,
    tx,
  }
}

export const callViaFeeProxy = async (
  { tx, maxPayment, account }: Awaited<ReturnType<typeof getAstoGasFee>>,
  signer: FetchSignerResult<Signer>
) => {
  const rootApi = await getRootApi()

  const feeProxyCall = rootApi.tx.feeProxy?.callWithFeePreferences?.(
    // asset id to use as gas payment
    ASTO_ASSET_ID,
    // maximum amount of gas payment in the asset id
    // multiply by 10 so it works. Shouldn't need it, though...
    maxPayment * BigInt(10),
    // call to execute once the gas payment is successful
    tx
  )

  await signAndSendEthereum({
    api: rootApi,
    account,
    tx: feeProxyCall,
    onSign: () => {},
    signer,
  })
}

export const signAndSendEthereum = async ({
  api,
  account,
  tx,
  onSign,
  signer,
}: {
  api: ApiPromise
  account: string
  tx: any
  onSign: () => void
  signer: FetchSignerResult<Signer>
}) => {
  if (!account) {
    throw new Error('Not signed in')
  }

  if (!signer) {
    throw new Error('No signer')
  }

  const { header, mortalLength, nonce } = await api!.derive.tx.signingInfo(
    account
  )

  const eraOptions = {
    address: account,
    blockHash: header?.hash,
    blockNumber: header?.number,
    era: api!.registry.createTypeUnsafe('ExtrinsicEra', [
      {
        current: header?.number,
        period: mortalLength,
      },
    ]),
    genesisHash: api!.genesisHash,
    method: tx.method,
    nonce,
    runtimeVersion: api!.runtimeVersion,
    signedExtensions: api!.registry.signedExtensions,
    version: api!.extrinsicVersion,
  }

  const payload = api!.registry.createTypeUnsafe('SignerPayload', [
    eraOptions,
  ]) as unknown as GenericSignerPayload
  const { data } = payload.toRaw()
  const hashed = data.length > (256 + 1) * 2 ? blake2AsHex(data) : data
  const ethPayload = blake2AsHex(hashed)

  const signature = await signer.provider
    //@ts-ignore
    ?.send('personal_sign', [ethPayload, account])
    ?.then((res: any) => {
      onSign()
      return res
    })

  tx.addSignature(account, signature, payload.toPayload())

  await new Promise<void>((resolve, reject) => {
    let unsubscribe: () => void

    tx.send(({ blockNumber, status, txIndex, dispatchError }: any) => {
      if (!status.isFinalized && !status.isInBlock) {
        return null
      }

      if (!dispatchError) {
        unsubscribe?.()

        return resolve()
      }

      if (!dispatchError.isModule) {
        unsubscribe?.()
        return reject(
          new Error(
            `Extrinsic failed ${JSON.stringify(dispatchError.toJSON())}`
          )
        )
      }

      const { section, name, docs } = dispatchError.registry.findMetaError(
        dispatchError.asModule
      )
      unsubscribe?.()
      return reject(
        new Error(`Extrinsic sending failed, [${section}.${name}] ${docs}`)
      )
    })
      .then((unsub: any) => {
        unsubscribe = unsub
      })
      .catch((err: any) => {
        reject(err)
      })
  })

  await new Promise<void>((res) => {
    setTimeout(res, 18000)
  })
}
