export const roundAccurately = (
  number: string | number,
  decimalPlaces: string | number
) =>
  Number(
    Math.round(parseFloat(number + 'e' + decimalPlaces)) + 'e-' + decimalPlaces
  )

export const isDev = () => {
  return process.env.NEXT_PUBLIC_ENVIRONMENT === 'development'
}

export const IS_MAINTENANCE_MODE =
  process.env.NEXT_PUBLIC_MAINTENANCE_MODE === '1' ? true : false
