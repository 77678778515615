// const DEV_CHAIN_RUL =
//   'https://eth-goerli.g.alchemy.com/v2/Zp02bpH8WD1GOb9e35bH5G70s4Gx0c_j'

// const DEV_CHAIN_RUL = 'https://porcini.au.rootnet.app'

const DEV_ROOT_JSON_RPC_URL = 'https://porcini.rootnet.app'

export const ROOT_JSON_RPC_URL =
  process.env.NEXT_PUBLIC_ROOT_JSON_RPC_URL || DEV_ROOT_JSON_RPC_URL

export const ASTO_ASSET_ID =
  Number(process.env.NEXT_PUBLIC_ASTO_ASSET_ID) || 17508
export const XRP_ASSET_ID = Number(process.env.NEXT_PUBLIC_XRP_ASSET_ID) || 2

// DEV CONTRACT ADDRESSES on GOERLI
// const DEV_ASTO = '0xa639481ffd85f921a0e7f4e9774756579f31b505';

// const DEV_ASTO = '0xcb977bcdfecebd9308740afdb34aa4b3f689729b'
// const DEV_TEMPUS = '0x957F7569116A5e5f9340Fd8f1f08E2b0782aE863'
// const DEV_COMIC = '0x176Da3d836815a73147ec13695A18ec3365a32B6'
// const DEV_COMIC_MINT = '0x0d37225d80ee1124b83D6dF50f262e3AFb3863CD'

/**
 *
 * ASTO PORCINI ADDRESS: You should be able to interact with it through the ERC20 precompile.
 * We can convert the Root standard asset_id (17508) to a 20 byte contract address by converting the 17508 to hex, then
 * prefixing with the ERC20 asset prefix (CCCCCCCC) Which gives: 0xCCCCCCCC00004464000000000000000000000000
 * If you call ERC20 functions on the above contract address, it will work with it the same way you would interact with any ERC20 token
 *
 */

const DEV_ASTO = '0xCCCCCCCC00004464000000000000000000000000'
const DEV_ASTO_MAIN = '0xBdc58b286D0e7d433bBcC84a2b7c35f43212EC6C'
const DEV_TEMPUS = '0xC936FA66ccafd36B5f4833A6385C82C97B0B9F64'
const DEV_COMIC = '0x20eE33E9a0F25F7e279Bf6f829240863A44Cbf7d'
const DEV_COMIC_STORAGE = '0xe46F8D7E26d0C5d8A371A812D1cB138Fc1D4d416'
const DEV_COMIC_MINT = '0xED7e20cF7cbC2434f94cFFad2F42D4DfDfBC2aff'
const DEV_IDENTITY_CONTRACT = '0x3d33e2bf871c699E4fdd760a2a222ed98B3e258e'
const DEV_BRIDGE_CONTRACT = '0xc48cc24cd6A119bDF677876e06D2E3a1946FEB1d'
const DEV_PEG_CONTRACT = '0xB40Cf258CAd4c337dB4e52b57eDc333c3E2720A3'

// CONTRACT ADDRESSES with env var overwrite
const ASTO = process.env.NEXT_PUBLIC_ASTO || DEV_ASTO
const ASTO_MAIN = process.env.NEXT_PUBLIC_ASTO_MAIN || DEV_ASTO_MAIN
const TEMPUS = process.env.NEXT_PUBLIC_TEMPUS || DEV_TEMPUS
const TEMPUS_COMIC = process.env.NEXT_PUBLIC_COMIC || DEV_COMIC
const TEMPUS_COMIC_STORAGE =
  process.env.NEXT_PUBLIC_COMIC_STORAGE || DEV_COMIC_STORAGE
const TEMPUS_COMIC_MINT = process.env.NEXT_PUBLIC_COMIC_MINT || DEV_COMIC_MINT
const IDENTITY =
  process.env.NEXT_PUBLIC_IDENTITY_CONTRACT || DEV_IDENTITY_CONTRACT
const BRIDGE_CONTRACT = process.env.NEXT_PUBLIC_BRIDGE || DEV_BRIDGE_CONTRACT
const PEG_CONTRACT = process.env.NEXT_PUBLIC_PEG_CONTRACT || DEV_PEG_CONTRACT

export const WALLET_CONNECT_PROJECT_ID =
  process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID
export const XAMAN_KEY = process.env.NEXT_PUBLIC_XAMAN_KEY

export const FPASS_STAGE =
  process.env.NEXT_PUBLIC_FPASS_PROD_OR_DEV === 'production'
    ? 'production'
    : 'development'

export const CONTRACT_ADDRESSES = {
  FEE_PROXY: '0x00000000000000000000000000000000000004bb',
  ASTO,
  ASTO_MAIN,
  TEMPUS,
  TEMPUS_COMIC,
  TEMPUS_COMIC_STORAGE,
  TEMPUS_COMIC_MINT,
  IDENTITY,
  BRIDGE_CONTRACT,
  PEG_CONTRACT,
}

export const PREQUEL_BOOK_ID = 'clbuakbx00070skou1w756srn'

export const THE_ABERRANT_SPART_1ST_EDITION_COLLECTION_ID = Number(
  process.env.THE_ABERRANT_SPART_1ST_EDITION_COLLECTION_ID ?? '1'
)
export const THE_ABERRANT_SPART_2ND_EDITION_COLLECTION_ID = Number(
  process.env.THE_ABERRANT_SPART_2ND_EDITION_COLLECTION_ID ?? '2'
)
export const AGE_OF_THE_STARSTONE_1ST_EDITION_COLLECTION_ID =
  Number(process.env.AGE_OF_THE_STARSTONE_1ST_EDITION_COLLECTION_ID) ?? ''
export const AGE_OF_THE_STARSTONE_2ND_EDITION_COLLECTION_ID =
  Number(process.env.AGE_OF_THE_STARSTONE_2ND_EDITION_COLLECTION_ID) ?? ''
