import { ethers } from 'ethers6'

import {
  getContract,
  contractCallWithAstoGas,
  serializeContractCall,
  getAstoGasFee,
  callViaFeeProxy,
} from '../contractInstances'
import { CONTRACT_ADDRESSES as contractAddresses } from '../constants'
import { FetchSignerResult, Signer } from '@wagmi/core'
import { User } from 'utils/user'

export const getStakedAsto = async (signer: FetchSignerResult<Signer>) => {
  const tempusContract = await getContract('TEMPUS', signer)
  const address = await signer?.getAddress()

  if (!address || !tempusContract) return undefined

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return await tempusContract.getAstoBalance!(address)
}

export const getFreeAsto = async (signer: FetchSignerResult<Signer>) => {
  const tempusContract = await getContract('TEMPUS', signer)
  const address = await signer?.getAddress()

  if (address === '' || !tempusContract) return undefined

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return await tempusContract.getFreeAstoBalance!(address)
}

export const getTempusPoints = async (signer: FetchSignerResult<Signer>) => {
  const tempusContract = await getContract('TEMPUS', signer)
  const address = await signer?.getAddress()

  if (address === '' || !tempusContract) return undefined

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return await tempusContract.getTempusBalance!(address)
}

export const checkAllowance = async (
  amount: string,
  address: string,
  signer: FetchSignerResult<Signer>
) => {
  const astoContract = await getContract('ASTO', signer)

  if (!amount || !astoContract) return undefined

  const astoAmount = BigInt(amount?.padEnd(amount.length + 18, '0'))

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const allowance: bigint = await astoContract.allowance!(
    address,
    contractAddresses['TEMPUS']
  )

  return allowance < astoAmount
}

export const approveAsto = async (
  amount: string,
  user: User,
  signer: FetchSignerResult<Signer>,
  delegate?: string
) => {
  const astoAmount = ethers.parseEther(amount)

  const callData = await serializeContractCall('ASTO', signer, 'approve', [
    delegate ?? contractAddresses['TEMPUS'],
    astoAmount,
  ])

  const callDataWithGas = await getAstoGasFee(callData)

  return await callViaFeeProxy(callDataWithGas, signer)
}

export const stakeAsto = async (
  amount: string,
  user: User,
  signer: FetchSignerResult<Signer>
) => {
  const astoAmount = ethers.parseEther(amount)
  const account = await signer?.getAddress()

  const callData = await serializeContractCall('TEMPUS', signer, 'stake', [
    account,
    astoAmount,
  ])

  const callDataWithGas = await getAstoGasFee(callData)

  return await callViaFeeProxy(callDataWithGas, signer)
}

export const withdrawAsto = async (
  amount: string,
  signer: FetchSignerResult<Signer>
) => {
  const astoAmount = ethers.parseEther(amount)
  const account = await signer?.getAddress()

  return await contractCallWithAstoGas('TEMPUS', signer!, 'unstake', [
    account,
    astoAmount,
  ])
}
