import type { UserSession } from '@futureverse/react'

import * as React from 'react'

import { useFutureverse } from '@futureverse/react'
import { ethers } from 'ethers6'
import * as wagmi from 'wagmi'

import { roundAccurately } from './utils'
import {
  getStakedAsto as getStakedAsto,
  getFreeAsto,
  getTempusPoints,
} from './contractAPIs/tempusAPI'
import { FetchSignerResult, Signer } from '@wagmi/core'
import { AddressBrand } from '@futureverse/experience-sdk'

export type User = Omit<UserSession, 'eoa'> & {
  eoa: `0x${string}`
  futurepass: string & AddressBrand
  balances: {
    astoBalance: number
    stakedAstoBalance: number
    freeAstoBalance: number
    tempusBalance: number
  } | null
}

const UserContext = React.createContext<{
  user: User | null
  login: () => void
  logout: () => void
  refetchBalances: () => void
} | null>(null)

export function UserProvider({ children }: { children: React.ReactNode }) {
  const {
    userSession: fvUserSession,
    login: fvLogin,
    logout,
  } = useFutureverse()

  const login = React.useCallback(() => {
    window.sessionStorage.setItem('tempus::redirect', window.location.pathname)
    fvLogin()
  }, [fvLogin])

  const [shouldRefetchBalances, setShouldRefetchBalances] =
    React.useState(false)

  const refetchBalances = React.useCallback(() => {
    setShouldRefetchBalances(true)
  }, [])

  const [user, setUser] = React.useState<User | null>(null)

  const { data: signer } = wagmi.useSigner()
  const { data } = wagmi.useBalance({
    address: user?.eoa,
    chainId: user?.chainId,
    token: '0xcCcCCccC00004464000000000000000000000000',
  })

  React.useEffect(() => {
    if (data?.value) {
      setUser((user) => {
        if (!user) {
          return null
        }

        return {
          ...user,
          balances: {
            stakedAstoBalance: 0,
            freeAstoBalance: 0,
            tempusBalance: 0,
            ...user.balances,
            astoBalance: roundAccurately(
              ethers.formatEther(data.value.toString()),
              3
            ),
          },
        }
      })
    }
  }, [data?.value])

  // Previous implementation used eoa to stake, so we have to keep the checking the balance in eoa
  const fetchBalances = React.useCallback(() => {
    if (!signer) return

    setShouldRefetchBalances(false)

    getBalances(signer)
      .then(([stakedAstoBalance, freeAstoBalance, tempusBalance]) => {
        setUser((user) => {
          if (!user) return null

          return {
            ...user,
            balances: {
              astoBalance: user.balances?.astoBalance || 0,
              stakedAstoBalance,
              freeAstoBalance,
              tempusBalance,
            },
          }
        })
      })
      .catch((err) => {
        console.log('Balance Fetch Error', err)
      })
  }, [signer])

  React.useEffect(() => {
    if (!fvUserSession) {
      setUser(null)
    } else {
      setUser({
        ...fvUserSession,
        eoa: fvUserSession.eoa as `0x${string}`,
        futurepass: fvUserSession.user?.profile.futurepass as `0x${string}` &
          AddressBrand,
        balances: null,
      })

      setShouldRefetchBalances(true)
    }
  }, [fvUserSession])

  React.useEffect(() => {
    if (shouldRefetchBalances) {
      fetchBalances()
    }
  }, [fetchBalances, shouldRefetchBalances])

  return (
    <UserContext.Provider value={{ user, refetchBalances, login, logout }}>
      {children}
    </UserContext.Provider>
  )
}

export function useUser() {
  const context = React.useContext(UserContext)

  if (context === null) {
    // console.error('useUser must be used within a UserProvider')
    throw new Error('useUser must be used within a UserProvider')
  }

  // This is breaking TS, but we can't throw errors in Next without
  // blowing up the app, so... The console error will have to do :see_no_evil:
  return context as Exclude<typeof context, null>
}

///////////////////////

export async function getBalances(signer: FetchSignerResult<Signer>) {
  return await Promise.all([
    getStakedAsto(signer)
      .then((balance) => {
        return roundAccurately(ethers.formatEther(balance), 3)
      })
      .catch((err) => {
        console.log('getStakedAsto failed with:', err.message)
        return 0
      }),
    getFreeAsto(signer)
      .then((balance) => {
        return roundAccurately(ethers.formatEther(balance), 3)
      })
      .catch((err) => {
        console.log('getFreeAsto failed with:', err.message)
        return 0
      }),
    getTempusPoints(signer)
      .then((balance) => {
        return roundAccurately(ethers.formatEther(balance), 3)
      })
      .catch((err) => {
        console.log('getTempusBalance failed with:', err.message)
        return 0
      }),
  ])
}
