import { ApiPromise, WsProvider } from '@polkadot/api'

import { ENVIRONMENT } from './config'
import { ASTO_ASSET_ID } from 'utils/constants'
import { signAndSendEthereum } from './contractInstances'
import { BigNumber, utils } from 'ethers'
import { FetchSignerResult, Signer } from '@wagmi/core'
import { ethers } from 'ethers6'

const providerUrl = ENVIRONMENT.chain.rpcUrls.default.webSocket?.[0] ?? ''

export { blake2AsHex } from '@polkadot/util-crypto'
export type { ApiPromise } from '@polkadot/api'
export type { GenericSignerPayload } from '@polkadot/types'

let api: ApiPromise | undefined

export const getRootApi = async () => {
  if (api) return api

  api = await ApiPromise.create({
    noInitWarn: true,
    provider: new WsProvider(providerUrl),
    rpc: {
      dex: {
        quote: {
          description:
            'Given some amount of an asset and pair reserves, returns an equivalent amount of the other asset',
          params: [
            {
              name: 'amountA',
              type: 'u128',
            },
            {
              name: 'reserveA',
              type: 'u128',
            },
            {
              name: 'reserveB',
              type: 'u128',
            },
          ],
          type: 'Json',
        },
        getAmountsOut: {
          description:
            'Given an array of AssetIds, return amounts out for an amount in',
          params: [
            {
              name: 'amountIn',
              type: 'Balance',
            },
            {
              name: 'path',
              type: 'Vec<AssetId>',
            },
          ],
          type: 'Json',
        },
        getAmountsIn: {
          description:
            'Given an array of AssetIds, return amounts in for an amount out',
          params: [
            {
              name: 'amountOut',
              type: 'balance',
            },
            {
              name: 'path',
              type: 'Vec<AssetId>',
            },
          ],
          type: 'Json',
        },
        getLPTokenID: {
          description:
            'Given two AssetIds, return liquidity token created for the pair',
          params: [
            {
              name: 'assetA',
              type: 'AssetId',
            },
            {
              name: 'assetB',
              type: 'AssetId',
            },
          ],
          type: 'Json',
        },
        getLiquidity: {
          description: 'Given two AssetIds, return liquidity',
          params: [
            {
              name: 'assetA',
              type: 'AssetId',
            },
            {
              name: 'assetB',
              type: 'AssetId',
            },
          ],
          type: 'Json',
        },
        getTradingPairStatus: {
          description:
            'Given two AssetIds, return whether trading pair is enabled or disabled',
          params: [
            {
              name: 'assetA',
              type: 'AssetId',
            },
            {
              name: 'assetB',
              type: 'AssetId',
            },
          ],
          type: 'hex',
        },
      },
      ethy: {
        getEventProof: {
          description: 'Get ETH event proof for event Id',
          params: [
            {
              name: 'eventId',
              type: 'EventProofId',
            },
          ],
          type: 'Option<EthEventProofResponse>',
        },
        getXrplTxProof: {
          description: 'Get XRPL event proof for event Id',
          params: [
            {
              name: 'eventId',
              type: 'EventProofId',
            },
          ],
          type: 'Option<XrplEventProofResponse>',
        },
      },
      nft: {
        ownedTokens: {
          description: 'Get all NFTs owned by an account',
          params: [
            {
              name: 'collectionId',
              type: 'CollectionUuid',
            },
            {
              name: 'who',
              type: 'AccountId',
            },
            { name: 'cursor', type: 'SerialNumber' },
            { name: 'limit', type: 'u16' },
          ],
          type: '(SerialNumber, u128, Vec<SerialNumber>)',
        },
        tokenUri: {
          description: 'Get the URI of a token',
          params: [
            {
              name: 'tokenId',
              type: 'TokenId',
            },
          ],
          type: 'Vec<u8>',
        },
      },
    },
    types: {
      AccountId: 'EthereumAccountId',
      AccountId20: 'EthereumAccountId',
      AccountId32: 'EthereumAccountId',
      Address: 'AccountId',
      LookupSource: 'AccountId',
      Lookup0: 'AccountId',
      AssetId: 'u32',
      Balance: 'u128',
      EventProofId: 'u64',
      ValidatorSetId: 'u64',
      EthereumSignature: {
        r: 'H256',
        s: 'H256',
        v: 'U8',
      },
      ExtrinsicSignature: 'EthereumSignature',
      EthyId: '[u8; 32]',
      EthWalletCall: {
        nonce: 'u32',
      },
      XRPLTxData: {
        _enum: {
          Payment: {
            amount: 'Balance',
            destination: 'H160',
          },
          CurrencyPayment: {
            amount: 'Balance',
            address: 'H160',
            currencyId: 'H256',
          },
        },
      } as any,
      EthEventProofResponse: {
        event_id: 'EventProofId',
        signatures: 'Vec<Bytes>',
        validators: 'Vec<AccountId20>',
        validator_set_id: 'ValidatorSetId',
        block: 'H256',
        tag: 'Option<Bytes>',
      },
      XrplEventProofResponse: {
        event_id: 'EventProofId',
        signatures: 'Vec<Bytes>',
        validators: 'Vec<Bytes>',
        validator_set_id: 'ValidatorSetId',
        block: 'H256',
        tag: 'Option<Bytes>',
      },
      VersionedEventProof: {
        _enum: {
          sentinel: null,
          EventProof: 'EventProof',
        },
      },
      CollectionUuid: 'u32',
      SerialNumber: 'u32',
      TokenId: '(CollectionUuid, SerialNumber)',
    },
  })

  return api
}

export const getFpAddress = async ({ eoa }: { eoa: string }) => {
  const api = await getRootApi()

  // @ts-ignore
  const res = await api.query.futurepass.holders(eoa)

  return res.toJSON() as string
}

export const collectionInfo = async ({
  collectionId,
}: {
  collectionId: number
}) => {
  if (!collectionId) {
    return { collectionIssuance: 0 }
  }

  const api = await getRootApi()

  // @ts-ignore
  const res = await api.query.nft.collectionInfo(collectionId)

  return res.toJSON() as { collectionIssuance: number }
}

export const subscribeCollectionInfo = async ({
  collectionId,
  callback,
}: {
  collectionId: number
  callback: (nfts: number) => void
}) => {
  if (!collectionId) {
    return { collectionIssuance: 0 }
  }

  const api = await getRootApi()

  return api.query.nft.collectionInfo(collectionId, (res) => {
    if (res.value?.collectionIssuance) {
      callback(res.value?.collectionIssuance.toNumber())
    }
  })
}

export const ownedTokens = async ({
  collectionId,
  address,
}: {
  collectionId: number | null
  address: string
}) => {
  if (!collectionId) {
    return {
      quantity: 0,
      indexes: [],
    }
  }

  const api = await getRootApi()

  // @ts-ignore
  const res = await api.rpc.nft.ownedTokens(collectionId, address, 0, 1000)

  const json = res.toJSON()

  return {
    quantity: json[1] as number,
    indexes: json[2] as number,
  }
}

export const approveTransfer = async ({
  assetId = ASTO_ASSET_ID, // ASTO
  delegateAddress,
  amount,
}: {
  assetId?: number
  delegateAddress: string
  amount: string
}) => {
  const api = await getRootApi()

  //@ts-ignore
  return api.tx.assets.approveTransfer(assetId, delegateAddress, amount)
}

export const feeProxy = async ({
  assetId = ASTO_ASSET_ID, // ASTO
  tx,
}: {
  assetId?: number
  tx: any
}) => {
  const api = await getRootApi()

  //@ts-ignore
  return api.tx.feeProxy.callWithFeePreferences(
    assetId,
    BigInt('1000000000000000000000'),
    tx
  )
}

export const getMintWithFPTx = async ({
  collectionId,
  quantity,
  gas,
  eoa,
  futurepass,
}: {
  collectionId: number
  quantity: number
  gas: 'XRP' | 'ROOT'
  eoa: string
  futurepass: string
}) => {
  const api = await getRootApi()

  //@ts-ignore
  const mintTx = api.tx.nft.mint(collectionId, quantity, futurepass)
  //@ts-ignore
  const proxyExtrinsicTx = api.tx.futurepass.proxyExtrinsic(futurepass, mintTx)
  let tx
  if (gas === 'XRP') {
    tx = proxyExtrinsicTx
  } else {
    //@ts-ignore
    tx = api.tx.feeProxy.callWithFeePreferences(
      1,
      BigInt('10000000'),
      proxyExtrinsicTx
    )
  }

  const paymentInfo = await tx.paymentInfo(eoa)
  const estimatedFee = BigNumber.from(paymentInfo.partialFee.toString())
  const gasFee = utils.formatUnits(estimatedFee, 6)

  return { tx, gasFee }
}
export const mintWithFP = async ({
  collectionId,
  quantity,
  gas,
  eoa,
  futurepass,
  signer,
}: {
  collectionId: number
  quantity: number
  gas: 'XRP' | 'ROOT'
  eoa: string
  futurepass: string
  signer: FetchSignerResult<Signer>
}) => {
  const api = await getRootApi()

  //@ts-ignore
  const mintTx = api.tx.nft.mint(collectionId, quantity, futurepass)
  //@ts-ignore
  const proxyExtrinsicTx = api.tx.futurepass.proxyExtrinsic(futurepass, mintTx)
  let tx
  if (gas === 'XRP') {
    tx = proxyExtrinsicTx
  } else {
    //@ts-ignore
    tx = api.tx.feeProxy.callWithFeePreferences(
      1,
      BigInt('10000000'),
      proxyExtrinsicTx
    )
  }

  await signAndSendEthereum({
    api,
    account: eoa,
    tx,
    onSign: () => {},
    signer,
  })
}

export const queryRoot = async (address: string) => {
  const api = await getRootApi()

  //@ts-ignore
  const res = await api.query.system.account(address)

  const { free, frozen } = (
    res.toJSON() as {
      data: {
        flags: string
        free: string
        frozen: string
        reserved: string
      }
    }
  ).data

  const freeRootBigInt = ethers.toBigInt(free) - ethers.toBigInt(frozen)
  const freeRoot = ethers.formatUnits(freeRootBigInt, 6)

  return freeRoot.toString()
}
